<template>
  <div class="tips-button" @click='handleClick'>
    <div class="tips-button-info">
      <div class="focus" :style="buttonStyle">
        <a href="javascript:void(0);" :style="ATagStyle">{{ buttonText }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'tipsButton',
  props: ['moduleTagNumber', 'moduleInfo'],
  data () {
    return {
      buttonText: '',
      buttonStyle: {},
      ATagStyle: {}
    }
  },
  computed: {
    ...mapState(['curModuleSelected'])
  },
  watch: {
    curModuleSelected: {
      handler (newV) {
        if (newV) {
          // ;
          if (this.moduleInfo.tagNumber === newV.tagNumber) {
            this.initStyle(newV)
            this.buttonText = newV.buttonText
          }
        }
      },
      deep: true
    },
    moduleInfo: {
      handler (newV) {
        if (newV && newV.buttonText) {
          this.buttonText = newV.buttonText
          this.initStyle(newV)
        }
      },
      // !防止拖拽结束后内容突然消失的bug
      immediate: true
    }
  },
  methods: {
    handleClick () {
      this.$emit('handleCurModuleClick')
    },
    initStyle (styleInfo) {
      const {
        buttonText,
        buttonBgColor,
        buttonColor,
        buttonBorderColor,
        fontWeightStyle,
        marginTopValue,
        marginBottomValue
      } = styleInfo
      this.buttonText = buttonText
      this.buttonStyle = {
        backgroundColor: buttonBgColor,
        fontWeight: fontWeightStyle,
        marginTop: `${marginTopValue}px`,
        marginBottom: `${marginBottomValue}px`,
        color: buttonColor
      }
      this.ATagStyle = buttonBorderColor === '#FFFFFF' ? {
        border: 0,
        fontSize: '15px'
      } : {
        border: `2px solid  ${buttonBorderColor}`,
        fontSize: '15px'
      }
      // if(buttonBorderColor !== '#FFFFFF'){

      // }
      // this.ATagStyle = {
      //   border: `2px solid  ${buttonBorderColor}`
      // };
    }
  }
}
</script>

<style lang="scss" scoped>
.tips-button {
  width: 100%;
  height: 68px;
  position: relative;
  z-index:100;
  .tips-button-info {
    // margin: 14px 0;
    .focus {
      text-align: center;
      margin: 0 92.5px;
      border-radius: 6px;
      width:auto;
      height:40px;
      display: flex;
      justify-content: center;
      align-items: center;
      // !微信新建推广页上面的border-radius 是4px 但本地看起来4px比较
      a {
        border-radius: inherit;
        text-decoration: none;
        height: 40px;
        line-height: 40px;
        font-size:15px;
        width: 100%;
        color:inherit;
        display: inline-block;
        font-weight: inherit;

      }
    }
  }
}
</style>
