<template>
  <div
    class="tips-image"
    v-if="!chooseImage"
    ref="tipsImage"
    @click.stop="handleClick"
    v-loading="loading"
  >
    <div class="tips">
      <i class="iconfont icon-tupian"></i>
      <div class="upload-button" @click.stop="fakeUpload">
        本地上传
      </div>
      <!-- <input
        class="local-input"
        type="file"
        :ref="`imageFile${curModuleSelected.tagNumber}`"
        @change="handleFileChange"
      /> -->
      <input
        class="local-input"
        type="file"
        :ref="`imageFile${moduleInfo.tagNumber}`"
        @change="handleFileChange"
      />
    </div>
    <cropperModel
      :cropperVisible="cropperVisible"
      :imageUrl="url"
      :trueWidth="trueWidth"
      :trueHeight="trueHeight"
      :fileName="fileName"
      :needCropWidth="375"
      :needCropHeight="375"
      :myCropperWidth="750 / 1.5"
      :myCropperHeight="750 / 1.5"
      :fixedBox="false"
      @cropSuccess="handleCropSuccess"
      @close="handleCropClose"
    ></cropperModel>
  </div>
  <div class="image-item" v-else @click.stop="handleClick">
    <!-- <img :src="cropperUrl" alt="" /> -->
    <img :src="moduleInfo.imgUrl" alt="" />
  </div>
</template>

<script>
import cropperModel from './../cropper-model'
import { mapState, mapMutations } from 'vuex'
import { uploadFile } from '@/api/common'
const LIMIWIDTH = 750 // 最大宽度
const LIMITHEIGHT = 1536 // 最大高度
export default {
  name: 'tipsImage',
  // props:['moduleTagNumber'],
  props: ['moduleTagNumber', 'moduleInfo'],
  data () {
    return {
      chooseImage: false,
      cropperVisible: false,
      url: '',
      trueWidth: 0, // 原始图片的宽度
      trueHeight: 0, // 原石图片的高度
      cropperUrl: '', // 剪裁过后的图片地址
      fileName: '', // 上传图片的名称
      fileInfo: null, // 当前组件的文件信息
      loading: false
    }
  },
  computed: {
    ...mapState(['curModuleSelected', 'isUploadImage'])
  },
  watch: {
    curModuleSelected: {
      handler (newV) {
        if (newV && Object.keys(newV).length > 0) {
          if (this.moduleInfo.tagNumber === newV.tagNumber) {
            if (newV.imgUrl) {
              this.cropperUrl = newV.imgUrl
              this.chooseImage = true
            }
          }
        }
      },
      deep: true
    },
    // !页面刷新之后进入显示之前编辑的内容
    moduleInfo: {
      handler (newV) {
        if (newV && newV.imgUrl) {
          this.chooseImage = true
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setIsUploadImage', 'setCurModuleSelected']),
    fakeUpload () {
      // 模拟上传文件的点击事件

      if (`imageFile${this.moduleInfo.tagNumber}` in this.$refs) {
        this.$refs[`imageFile${this.moduleInfo.tagNumber}`].click()
      }
    },
    handleClick () {
      this.$emit('handleCurModuleClick')
    },
    handleCropClose () {
      this.cropperVisible = false
      this.$refs[`imageFile${this.moduleInfo.tagNumber}`].value = ''
    },
    handleCropSuccess (file) {
      const targetFile = file
      this.loading = true
      uploadFile({ file: targetFile })
        .then(res => {
          this.$refs[`imageFile${this.moduleInfo.tagNumber}`].value = ''
          this.cropperUrl = res
          this.chooseImage = true
          const obj = JSON.parse(JSON.stringify(this.curModuleSelected))
          obj.imgUrl = this.cropperUrl
          obj.imageFile = this.fileInfo
          this.setCurModuleSelected(obj)
          this.setIsUploadImage(false)
        }).catch(() => {
          this.$refs[`imageFile${this.moduleInfo.tagNumber}`].value = ''
        })
        .finally(() => {
          // !解决选择重复图片不触发input type='file' change事件的bug
          this.loading = false
        })
      // const fr = new FileReader()
      // fr.readAsDataURL(targetFile)
      // fr.onload = e => {
      //   this.cropperUrl = e.currentTarget.result
      //   this.chooseImage = true
      //   this.$refs.tipsImage.style = 'height:auto !important;'
      //   const obj = JSON.parse(JSON.stringify(this.curModuleSelected))
      //   obj.imgUrl = this.cropperUrl
      //   obj.imageFile = this.fileInfo
      //   this.setCurModuleSelected(obj)
      //   this.setIsUploadImage(false)
      // }
    },
    handleFileChange (e) {
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0]
      if (targetFile.size > 2000 * 1024) {
        this.$message({
          showClose: true,
          message: '您上传的图片太大，请重新上传!',
          type: 'error'
        })
        return
      }
      const fr = new FileReader()
      fr.readAsDataURL(targetFile)

      fr.onload = e => {
        const image = new Image()
        image.src = e.currentTarget.result
        image.onload = async () => {
          const { width, height } = image
          if (width < LIMIWIDTH) {
            this.$message({
              showClose: true,
              message: '您选择的图片宽度太小,请重新上传!',
              type: 'error'
            })
            return
          }
          // 如果图片的宽高不符合要求
          if (height > LIMITHEIGHT) {
            this.$message({
              showClose: true,
              message: '您选择的图片高度太高,请重新上传!',
              type: 'error'
            })
            return
          }
          // 如果宽度刚好等于750 则不需要裁剪直接显示
          if (width === LIMIWIDTH) {
            this.loading = true
            this.fileInfo = targetFile
            const obj = JSON.parse(JSON.stringify(this.curModuleSelected))
            // this.cropperUrl = e.currentTarget.result;
            // obj.imgUrl = this.cropperUrl;
            // obj.imageFile = this.fileInfo;
            // this.setCurModuleSelected(obj);
            // this.chooseImage = true;
            // return;
            // uploadFile({file:targetFile}).then(res=>{
            //   this.cropperUrl = res;
            //   obj.imgUrl = this.cropperUrl;
            //   obj.imageFile = this.fileInfo;
            //   this.setCurModuleSelected(obj);
            //   this.chooseImage = true;
            // })
            const res = await uploadFile({ file: targetFile })
            this.cropperUrl = res
            obj.imgUrl = this.cropperUrl
            obj.imageFile = this.fileInfo
            this.setCurModuleSelected(obj)
            this.chooseImage = true
            // !解决选择重复图片不触发input type='file' change事件的bug
            this.$refs[`imageFile${this.moduleInfo.tagNumber}`].value = ''
            this.loading = false
            return
          }
          this.trueWidth = width
          this.trueHeight = height
          this.fileName = targetFile.name
          this.url = e.currentTarget.result
          this.cropperVisible = true
          this.fileInfo = targetFile
        }
      }
    }
  },
  components: {
    cropperModel
  }
}
</script>
<style lang="scss" scoped>
.tips {
  text-align: center;
}
input[type="file"] {
  display: none;
}
.tips-image {
  width: 375px;
  height: 222px !important;
  max-height: 768px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  .icon-tupian {
    font-size: 24px;
    color: rgb(176, 176, 176);
  }
  .upload-button {
    margin-top: 10px;
    padding: 12px 34px;
    font-size: 13px;
    color: #6b6b6b;
    letter-spacing: 0;
    line-height: 22px;
    border: 1px dashed #d6d6d6;
    border-radius: 3px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
}
.image-item {
  // 设置font-size为0 防止多个图片之间出现间隔
  font-size: 0;
  position: relative;
  z-index: 100;
  img {
    width: 100%;
    // height: 222px;
  }
}
</style>
