// * 配置编辑项

export const baseModule = {
  // time: +new Date(), // 时间戳
  menu: null,
  config: [
    {
      moduleName: '图片',
      name: 'tipsImage', // 组件对应的名称
      styleModuleName: 'imageModule', // 组件对应的编辑样式的模块
      icon: 'icon-tupian', // 组件对应的icon
      id: 1
    },
    {
      moduleName: '文本',
      name: 'tipsText',
      icon: 'icon-wenzi',
      styleModuleName: 'textModule',
      id: 2
    }

  ]
}

export const buttonConversion = {
  config: [
    {
      moduleName: '关注公众号',
      name: 'tipsButton',
      styleModuleName: 'buttonModule',
      id: 1
    }
  ]
}
