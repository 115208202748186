<template>
  <div class="app-header" id='app-header'>
    <div class="header-info">
      <div class="right-part">
        <i
          class="iconfont icon-zuo1"
          style="margin-right:20px;cursor: pointer;color:#ccc"
          @click="goBack"
        ></i>
        <el-input
          type="text"
          style="width:240px;font-size:16px;"
          v-model="pageName"
          ref="pageNameInput"
        ></el-input>
        <i
          class="el-icon-edit"
          style="font-size:20px;margin-left:10px;color:#BDBEC0;cursor: pointer;"
          @click="getInputFocus"
        ></i>
      </div>
      <div class="middle-part">
        <el-select
          v-model="size"
          placeholder="请选择"
          style="padding-right:10px"
        >
          <el-option
            v-for="(item, index) in pageSize"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <div class="repeal-and-redo">
          <!-- <div>
            <i class="iconfont icon-chexiao1"></i>
            <span>撤销</span>
          </div> -->
          <div @click="resetPage" style="color:#666">
            <i class="iconfont icon-zhongzuo"></i>
            <span>重做</span>
          </div>
        </div>
      </div>
      <div class="left-part">
        <div style="color:#666666">
          <i class="el-icon-mobile-phone" @click="showPreView"></i>
          <span @click="showPreView">预览</span>
        </div>
        <div>
          <el-button type="primary" @click="nextStep">下一步</el-button>
        </div>
      </div>
      <!-- <div class="next-step">
        <el-button type="primary" @click="nextStep">下一步</el-button>
      </div> -->
    </div>
    <show-preview :isShowPreivew.sync="isShowPreivew"> </show-preview>
  </div>
</template>
<script>
import showPreview from './show-preview'
import { formatTime } from './../../../assets/js/utils'

import { mapState, mapMutations } from 'vuex'
export default {
  name: 'appHeader',
  data () {
    return {
      size: '1',
      isShowPreivew: false,
      pageName: '',
      pageSize: [
        {
          label: 'iPhone8 750*1334px',
          pageHeight: 667,
          value: '1'
        },
        {
          label: 'iPhoneX 750*1536px',
          pageHeight: 768,
          value: '2'
        },
        {
          label: '安卓全面屏 750*1500px',
          pageHeight: 750,
          value: '3'
        }
      ],
      num: 100
    }
  },
  watch: {
    size: {
      handler (newV) {
        const pageInfo = this.pageSize.find(item => item.value === newV)
        const { backgroundColor } = this.editAreaStyle
        this.setEditAreaStyle({
          backgroundColor,
          height: `${pageInfo.pageHeight}px`
        })
      }
    },
    pageName: {
      handler (newV) {
        if (newV.length > 30) {
          this.$message({
            showClose: true,
            message: '字符为1-30之间',
            type: 'error'
          })
        }
      }
    },
    canvasName: {
      handler (newV) {
        this.pageName = newV
      }
    }
  },
  computed: {
    ...mapState(['moduleSelectedList', 'canvasName', 'editAreaStyle'])
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapMutations(['setCanvasName', 'resetAllModule', 'setEditAreaStyle']),
    initData () {
      this.pageName = this.canvasName
        ? this.canvasName
        : `原生推广页${this.formateTime()}`
    },
    formateTime () {
      return formatTime(new Date()).replace(/((-)|(:))|(\s)/g, '')
    },
    getInputFocus () {
      this.$refs.pageNameInput.select()
    },
    handleNumChange () {},
    showPreView () {
      for (let i = 0; i < this.moduleSelectedList.length; i++) {
        const item = this.moduleSelectedList[i]
        const analyzingConditions =
          (item.name === 'tipsImage' && item.imgUrl) ||
          (item.name === 'tipsText' && item.promotedText) ||
          (item.name === 'tipsButton' && item.buttonText)
        if (!analyzingConditions) {
          this.$message({
            showClose: true,
            message: '您的图片组件配置有误!',
            type: 'error'
          })
          return
        }
      }
      this.isShowPreivew = true
    },
    resetPage () {
      this.$confirm('该操作将丢弃现有的修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.resetAllModule()
      })
    },
    goBack () {
      this.resetAllModule()
      setTimeout(() => {
        this.$router.push({
          name: 'DeliveryMaterialManage'
        })
      }, 50)
    },
    handleClose () {},
    nextStep () {
      for (let i = 0; i < this.moduleSelectedList.length; i++) {
        const item = this.moduleSelectedList[i]
        const analyzingConditions =
          (item.name === 'tipsImage' && item.imgUrl) ||
          (item.name === 'tipsText' && item.promotedText) ||
          (item.name === 'tipsButton' && item.buttonText)
        if (!analyzingConditions) {
          this.$message({
            showClose: true,
            message: '您的图片组件配置有误!',
            type: 'error'
          })
          return
        }
      }
      this.setCanvasName(this.pageName)
      this.$router.push({
        name: 'CompletePage'
      })
    }
  },
  components: {
    showPreview
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner {
  font-weight: bold;
}
.app-header {
  height: 56px;
  margin-top: 10px;
  .header-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 32px;
    border-bottom: 1px solid rgb(238, 238, 238);
    background-color: #fff !important;
    .right-part {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .middle-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .repeal-and-redo {
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
    .left-part {
      // flex-grow: 1;
      width: 368px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #666;
        i {
          font-size: 20px;
        }
        span {
          padding-top: 5px;
        }
      }
    }
  }
}
</style>
