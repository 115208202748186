<template>
  <div class="tips-text" @click='handleClick'>
    <div>
      <textarea
        class="custom-textarea"
        v-model="copyPromotedText"
        :style="textAreaStyle"
        ref="customTextarea"
        readonly
      ></textarea>
    </div>
    <div
      class="tips"
      v-show="!copyPromotedText"
      :style="tipsStyle"
    >
      请在右侧输入文本内容
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// 需要用到的样式
const CONTEXT_STYLE = [
  'letter-spacing',
  'line-height',
  'padding-top',
  'padding-bottom',
  'font-family',
  'font-weight',
  'font-size',
  'text-rendering',
  'text-transform',
  'width',
  'text-indent',
  'padding-left',
  'padding-right',
  'border-width',
  'box-sizing'
]
// 隐藏hiddenTextarea的默认布局样式
const HIDDEN_STYLE = `
  height:0 !important;
  visibility:hidden !important;
  overflow:hidden !important;
  position:absolute !important;
  z-index:-1000 !important;
  top:0 !important;
  right:0 !important
`

// 计算目标dom的样式
function calculateNodeStyling (targetElement) {
  const style = window.getComputedStyle(targetElement)
  const boxSizing = style.getPropertyValue('box-sizing')
  // 计算padding
  const paddingSize =
    parseFloat(style.getPropertyValue('padding-bottom')) +
    parseFloat(style.getPropertyValue('padding-top'))
  // 计算border
  const borderSize =
    parseFloat(style.getPropertyValue('border-bottom-width')) +
    parseFloat(style.getPropertyValue('border-top-width'))

  // 获取目标样式的具体值
  const contextStyle = CONTEXT_STYLE.map(
    name => `${name}:${style.getPropertyValue(name)}`
  ).join(';')

  return {
    boxSizing,
    paddingSize,
    borderSize,
    contextStyle
  }
}
export default {
  name: 'tipsText',
  props: ['moduleTagNumber', 'moduleInfo'],
  data () {
    return {
      copyPromotedText: '',
      tipsStyle: {},
      textAreaStyle: {},
      scrollHeight: 0, // 文本域的scrollheight
      textAreaHeight: '', // 文本域的高度
      minRows: 1, // 默认最小行数
      flag: false
    }
  },
  computed: {
    ...mapState(['promotedText', 'curModuleSelected'])
    // ...mapState(["curModuleSelected"])
  },
  watch: {
    curModuleSelected: {
      handler (newV) {
        if (newV) {
          //! 防止同一组件渲染相文本内容的bug
          if (this.moduleInfo.tagNumber === newV.tagNumber) {
            this.copyPromotedText = newV.promotedText
            this.$nextTick(() => {
              this.renderTipsStyle(newV)
              this.renderTextAreaStyle(newV)
              this.autoAdjustSize()
            })
          }
        }
      },
      deep: true
    },
    moduleInfo: {
      handler (newV) {
        if (newV && newV.promotedText) {
          this.copyPromotedText = newV.promotedText
          this.$nextTick(() => {
            this.renderTipsStyle(newV)
            this.renderTextAreaStyle(newV)
            this.autoAdjustSize()
          })
        }
      },
      immediate: true
    }
    // promotedText: {
    //   handler(newV) {
    //     this.copyPromotedText = newV;
    //     this.autoAdjustSize();
    //   },
    // },
  },
  methods: {
    renderTextAreaStyle (newV) {
      this.textAreaStyle = {
        fontSize: `${newV.fontSizeStyle}px`,
        color: `${newV.fontColor}`,
        fontWeight: `${newV.fontWeightStyle}px`,
        textAlign: `${newV.textAlignStyle}`
      }
    },
    handleClick () {
      this.$emit('handleCurModuleClick')
    },
    renderTipsStyle (newV) {
      const { textAlignStyle } = newV
      switch (textAlignStyle) {
        case 'left':
          this.tipsStyle = {
            left: '24px'
          }
          break
        case 'middle':
          this.tipsStyle = {
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
          }

          break
        case 'right':
          this.tipsStyle = {
            right: '24px'
          }
          break
      }
    },
    getTextAreaScrollHeight () {
      // this.$nextTick(() => {
      //   const { scrollHeight } = this.$refs.customTextarea;
      //   if(this.scrollHeight && scrollHeight !== this.scrollHeight){
      //     this.scrollHeight = scrollHeight;
      //     this.flag = true;
      //    }else{
      //      this.flag = false;
      //    }
      // });
    },
    // !自适应文字变化(包括文字大小变化和内容高度变化,参考element-ui的autosize的实现)
    autoAdjustSize () {
      const res = this.calcTextAreaHeight(
        this.$refs.customTextarea,
        this.minRows
      )
      this.$refs.customTextarea.style = `height:${res.height}`
    },
    calcTextAreaHeight (targetElement, minRows = 1, maxRows = null) {
      const result = {}
      // !通过临时创建一个textarea来编辑高度
      let hiddenTextarea = document.createElement('textarea')
      document.body.appendChild(hiddenTextarea)

      const {
        paddingSize,
        borderSize,
        boxSizing,
        contextStyle
      } = calculateNodeStyling(targetElement)
      hiddenTextarea.setAttribute('style', `${contextStyle};${HIDDEN_STYLE}`)
      hiddenTextarea.value =
        targetElement.value || targetElement.placeholder || ''

      let height = hiddenTextarea.scrollHeight
      if (boxSizing === 'border-box') {
        height = height + borderSize
      } else if (boxSizing === 'content-box') {
        height = height - paddingSize
      }
      // 重置value
      hiddenTextarea.value = ''
      // 计算单行高度
      const singleRowHeight = hiddenTextarea.scrollHeight - paddingSize
      if (minRows !== null) {
        let minHeight = singleRowHeight * minRows
        if (boxSizing === 'border-box') {
          // minHeight = minHeight + paddingSize + borderSize
          minHeight = minHeight + borderSize
        }
        height = Math.max(minHeight, height)
        result.minHeight = `${minHeight}px`
      }
      if (maxRows !== null) {
        let maxHeight = singleRowHeight * maxRows
        if (boxSizing === 'border-box') {
          maxHeight = maxHeight + paddingSize + borderSize
        }
        height = Math.min(maxHeight, height)
      }
      result.height = `${height}px`
      hiddenTextarea.parentNode &&
        hiddenTextarea.parentNode.removeChild(hiddenTextarea)
      hiddenTextarea = null
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.tips-text {
  position: relative;
  box-sizing: border-box;
  height: 42.5px;
  padding: 0 24px ;
  z-index:100;
  > div:nth-of-type(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    .custom-textarea {
      width: 100%;
      // height: 21px;
      // height:inherit;
      min-height: 21px;
      border: none;
      resize: none;
      color:inherit;
      outline: none; // 去除点击的黑边
      font-size: inherit;
      background-color:inherit;
      line-height: 1.5;
      font-weight: inherit;
      text-align: inherit;
      overflow-y: hidden;
    }
  }
  .tips {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    font-weight: bold;
    // font-size: 14px;
    font-size: inherit;
  }
}
</style>
