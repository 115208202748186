<template>
  <el-drawer
    title="推广页模板预览"
    append-to-body
    size="730px"
    :visible.sync="isShowPreivew"
    :before-close="handleClose"
  >
    <div class="show-preview">
      <div>
        <div class="wrapper" ref="wrapper" :style="scaleStyle">
          <div class="image-preview">
            <!-- <div class="wrapper" ref="wrapper"> -->
            <div class="image-content">
              <div v-for="item in copyModuleList" :key="item.tagNumber">
                <template v-if="item.name === 'tipsImage'">
                  <div class="img-part preview-part">
                    <img :src="item.imgUrl" alt="" />
                  </div>
                </template>
                <template v-else-if="item.name === 'tipsText'">
                  <div class="text-part preview-part" >
                    <textarea
                      class="preview-textarea"
                      v-model="item.promotedText"
                      :ref="`previewTextarea`"
                    >
                    </textarea>
                    <!-- <div>
                      {{item.promotedText}}
                    </div> -->
                  </div>
                </template>
                <template v-else>
                  <div class="button-part preview-part">
                    <div class="button-info">
                      <div class="focus">
                        <a href="javascript:void(0);">{{ item.buttonText }}</a>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="image-info">
          <p class="clearfix">
            <span style="font-size:14px;margin-right:10px">画布大小</span>
            <el-select v-model="sizeValue" placeholder="">
              <el-option
                v-for="item in phoneSizeList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
            <br />
            <span
              style="font-size:13px;color:#A3A3A3;float:right;margin-top:10px"
              >切换设备时页面效果可能不同</span
            >
            <br>
            <span style='font-size:13px;color:#A3A3A3;float:right;margin-top:10px'>实际效果以微信为准</span>

          </p>
          <p>
            <el-button type="primary" @click="handleClose" size="large"
              >取消</el-button
            >
          </p>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import BScroll from 'better-scroll'
const PHONE_SIZE_ONE = 276
const PHONE_SIZE_TWO = 242.188
const PHONE_SIZE_THREE = 248
const CONTEXT_STYLE = [
  'letter-spacing',
  'line-height',
  'padding-top',
  'padding-bottom',
  'font-family',
  'font-weight',
  'font-size',
  'text-rendering',
  'text-transform',
  'width',
  'text-indent',
  'padding-left',
  'padding-right',
  'border-width',
  'box-sizing'
]
// 隐藏hiddenTextarea的默认布局样式
const HIDDEN_STYLE = `
  height:0 !important;
  visibility:hidden !important;
  overflow:hidden !important;
  position:absolute !important;
  z-index:-1000 !important;
  top:0 !important;
  right:0 !important
`

// 计算目标dom的样式
function calculateNodeStyling (targetElement) {
  const style = window.getComputedStyle(targetElement)
  const boxSizing = style.getPropertyValue('box-sizing')
  // 计算padding
  const paddingSize =
    parseFloat(style.getPropertyValue('padding-bottom')) +
    parseFloat(style.getPropertyValue('padding-top'))
  // 计算border
  const borderSize =
    parseFloat(style.getPropertyValue('border-bottom-width')) +
    parseFloat(style.getPropertyValue('border-top-width'))

  // 获取目标样式的具体值
  const contextStyle = CONTEXT_STYLE.map(
    name => `${name}:${style.getPropertyValue(name)}`
  ).join(';')

  return {
    boxSizing,
    paddingSize,
    borderSize,
    contextStyle
  }
}

export default {
  name: 'showPreview',
  props: ['isShowPreivew'],
  data () {
    return {
      sizeValue: 1,
      copyModuleList: [],
      scale: 1, // 预览页面模块缩小比例
      scroll: null,
      scrollBarStyle: {
        height: '496px'
      },
      scaleStyle: {
        width: '375px'
      },
      imgPartStyle: {}, // 预览图片组件样式
      textPartStyle: {}, // 预览文字组件样式
      buttonPartStyle: {}, //  预览文字组件样式
      previewStyle: {},
      scrollHeight: 0, // 文本域的scrollheight
      textAreaHeight: '', // 文本域的高度
      minRows: 1, // 默认最小行数
      phoneSizeList: [
        {
          name: 'iPhone8 750 * 1335px',
          phoneSize: [750, 1335],
          id: 1
        },
        {
          name: 'iPhoneX 750 * 1536px',
          phoneSize: [750, 1536],
          id: 2
        },
        {
          name: '安卓全面屏 750 * 1500px',
          phoneSize: [750, 1500],
          id: 3
        }
      ]
    }
  },
  watch: {
    isShowPreivew: {
      handler (newV) {
        if (newV) {
          this.copyModuleList = JSON.parse(
            JSON.stringify(this.moduleSelectedList)
          )

          this.changePreviewSize(this.sizeValue)
          this.initStyle()
          this.autoAdjustSize()
        }
      }
    },
    sizeValue: {
      handler (newV) {
        if (newV) {
          this.changePreviewSize(newV)
          // this.initStyle();
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(['moduleSelectedList', 'editAreaStyle'])
  },
  mounted () {
    this.previewStyle = JSON.parse(JSON.stringify(this.editAreaStyle))
  },
  methods: {
    initStyle () {
      this.$nextTick(() => {
        this.renderModuleStyle()
        this.scroll = new BScroll(this.$refs.wrapper, {
          scrollY: true,
          click: true,
          bounce: {
            top: true,
            bottom: true,
            left: true,
            right: true
          },
          bounceTime: '2000',
          mouseWheel: true
        })
      })
    },
    autoAdjustSize () {
      setTimeout(() => {
        $('.preview-textarea')
          .each(function () {
            this.setAttribute(
              'style',
              'height:' + `${this.scrollHeight - 10}` + 'px;'
            )
          })
      }, 100)
    },
    renderModuleStyle () {
      const moduleList = [...document.querySelectorAll('.preview-part')]
      this.$nextTick(() => {
        this.copyModuleList.forEach((module, i) => {
          switch (module.name) {
            case 'tipsImage':
              // 样式后面是分号(;) 不是逗号(,)
              moduleList[i].style = `
               margin-top:${module.marginTopValue}px;
               margin-bottom:${module.marginBottomValue}px;
             `
              break
            case 'tipsText':
              moduleList[i].style = `
            font-size:${module.fontSizeStyle}px;
            color:${module.fontColor};
            line-height:${module.lineHeight ? module.lineHeight : 1.5};
            text-align:${module.textAlignStyle};
            font-weight:${module.fontWeightStyle};
            margin-top:${module.marginTopValue}px;
            margin-bottom:${module.marginBottomValue}px;

            `
              break
            case 'tipsButton':
              if (
                module.buttonBorderColor === '#FFFFFF' ||
                module.buttonBorderColor === '#ffffff'
              ) {
                moduleList[i].querySelector('.focus').style = `
                 background-color:${module.buttonBgColor};
                 font-weight:${module.fontWeightStyle};
                 margin-top:${module.marginTopValue}px;
                 margin-bottom:${module.marginBottomValue}px; 
                 color: ${module.buttonColor};
              `
              } else {
                moduleList[i].querySelector('.focus').style = `
                 background-color:${module.buttonBgColor};
                 font-weight:${module.fontWeightStyle};
                 margin-top:${module.marginTopValue}px;
                 margin-bottom:${module.marginBottomValue}px; 
                 color: ${module.buttonColor};
                 border:2px solid ${module.buttonBorderColor}
              `
              }
              break
          }
        })
      })
    },
    changePreviewSize (newV) {
      let scrollWidth
      switch (newV) {
        case 1:
          this.scale = parseFloat(PHONE_SIZE_ONE / 375)
          scrollWidth = PHONE_SIZE_ONE
          break
        case 2:
          this.scale = parseFloat(PHONE_SIZE_TWO / 375)
          scrollWidth = PHONE_SIZE_TWO
          break
        case 3:
          this.scale = parseFloat(PHONE_SIZE_THREE / 375)
          scrollWidth = PHONE_SIZE_THREE
          break
      }
      this.scaleStyle = {
        width: '375px',
        height: `${496 / this.scale}px`,
        overflow: 'hidden',
        transform: `scale(${this.scale})`,
        backgroundColor: `${this.editAreaStyle.backgroundColor}`
        // border: "6px solid #ccc",
        // borderRadius: "2.34px"
      }
      this.scrollBarStyle = {
        width: `${scrollWidth}px`
        // width:'375px',
        // height: "496px"
        // height:`${496 / this.scale}px`
      }
    },
    handleClose () {
      this.copyModuleList = []
      this.$emit('update:isShowPreivew', false)
    },
    calcTextAreaHeight (targetElement, minRows = 1, maxRows = null) {
      const result = {}
      // !通过临时创建一个textarea来编辑高度
      let hiddenTextarea = document.createElement('textarea')
      document.body.appendChild(hiddenTextarea)

      const {
        paddingSize,
        borderSize,
        boxSizing,
        contextStyle
      } = calculateNodeStyling(targetElement)
      hiddenTextarea.setAttribute('style', `${contextStyle};${HIDDEN_STYLE}`)
      hiddenTextarea.value =
        targetElement.value || targetElement.placeholder || ''

      let height = hiddenTextarea.scrollHeight
      if (boxSizing === 'border-box') {
        height = height + borderSize
      } else if (boxSizing === 'content-box') {
        height = height - paddingSize
      }
      // 重置value
      hiddenTextarea.value = ''
      // 计算单行高度
      const singleRowHeight = hiddenTextarea.scrollHeight - paddingSize
      if (minRows !== null) {
        let minHeight = singleRowHeight * minRows
        if (boxSizing === 'border-box') {
          // minHeight = minHeight + paddingSize + borderSize
          minHeight = minHeight + borderSize
        }
        height = Math.max(minHeight, height)
        result.minHeight = `${minHeight}px`
      }
      if (maxRows !== null) {
        let maxHeight = singleRowHeight * maxRows
        if (boxSizing === 'border-box') {
          maxHeight = maxHeight + paddingSize + borderSize
        }
        height = Math.min(maxHeight, height)
      }
      result.height = `${height}px`
      hiddenTextarea.parentNode &&
        hiddenTextarea.parentNode.removeChild(hiddenTextarea)
      hiddenTextarea = null
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  overflow: hidden;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.preview-part {
  display: inline-block;
}
.wrapper {
  position: relative;
  border: 1px solid #ccc;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    display: block;
  }
}
.preview-textarea {
  width: 100%;
  min-height: 21px;
  border: none;
  resize: none;
  color: inherit;
  outline: none;
  font-size: inherit;
  background-color: inherit;
  line-height: 1.5;
  font-weight: inherit;
  text-align: inherit;
  overflow-y: hidden;
}
.show-preview {
  position: relative;
  top: -40px;
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .image-preview {
      position: relative;
      .image-content {
        display: inline-block;
        > div {
          .img-part {
            img {
              width: 375px;
            }
          }
          .text-part {
            width: 375px;
            word-wrap: break-word;
            padding: 0 24px;
            > div {
              // margin: 11px 24px;
            }
          }
          .button-part {
            width: 375px;
            .button-info {
              .focus {
                text-align: center;
                margin: 0 92.5px;
                border-radius: 6px;
                width: auto;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                // !微信新建推广页上面的border-radius 是4px 但本地看起来4px比较
                a {
                  border-radius: inherit;
                  text-decoration: none;
                  height: 40px;
                  line-height: 40px;
                  width: 100%;
                  color: inherit;
                  display: inline-block;
                  font-weight: inherit;
                }
              }
            }
          }
        }
      }
    }
  }
  .image-info {
    align-self: flex-start;
    margin-top: 100px;
    margin-left: 10px;
    .clearfix {
      clear: both;
      content: "";
      display: block;
    }
    > p:nth-of-type(2) {
      position: absolute;
      bottom: 90px;
      left: 60%;
    }
  }
}
</style>
