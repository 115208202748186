<template>
  <div class="home">
    <app-header style="position: relative;z-index:100"></app-header>
    <div class="main-cantainer">
      <aside-bar class="nav-left"></aside-bar>
      <div class="edit-background">
        <edit-area class="custom-area" :pageDetail="pageDetail"></edit-area>
        <div id='bottom-empty'></div>
        <back-up-down targetUp='#app-header' targetDown='#bottom-empty' top='90%' ></back-up-down>
      </div>
      <div
        class="nav-right"
        v-if="curModuleSelected && Object.keys(curModuleSelected).length > 0"
      >
        <component :is="curModuleSelected.styleModuleName"></component>
      </div>
    </div>
  </div>
</template>

<script>
import appHeader from './components/app-header'
import asideBar from './components/aside-bar'
import editArea from './components/edit-area'
import imageModule from './components/style-module/image-module'
import textModule from './components/style-module/text-module'
import buttonModule from './components/style-module/button-module'
import backUpDown from './components/back-up-down'
import { getWeChatPageDetail } from '@/api/novelPut'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      styleModule: 'imageModule',
      scroll: null,
      pageDetail: null,
      isGetData: false
    }
  },
  created () {
    const pageId = this.$route.params.id
    window.localStorage.setItem('pageId', JSON.stringify(pageId))
    this.setPageId(pageId)
    if (pageId) {
      getWeChatPageDetail(pageId).then(res => {
        window.localStorage.setItem(
          'bgColor',
          JSON.stringify(res.backgroundColor)
        )
        // this.changeModuleSelectedOrder(res.items)
        this.isGetData = true
        this.pageDetail = res
      })
    }
  },
  computed: {
    ...mapState(['curModuleSelected'])
  },
  methods: {
    ...mapMutations([
      'setCurModuleSelected',
      'resetAllModule',
      'setPageId',
      'changeModuleSelectedOrder'
    ]),
    handleOnUnload () {},
    handleOnBeforeUnload () {}
  },
  beforeDestroy () {
    this.scroll = null
  },
  components: {
    appHeader,
    asideBar,
    editArea,
    imageModule,
    textModule,
    buttonModule,
    backUpDown
  }
}
</script>

<style lang="scss" scoped>
.page-component_scroll {
  height: 102%;
  .el-scrollbar_wrap {
    overflow: auto;
  }
}

.main-cantainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background: #f3f3f3;
  // overflow: hidden;
  .nav-left {
    position: sticky;
    top: 0;
    width: 340px;
    align-self: flex-start;
    background: #fff;
    height: 777px;
  }
  .edit-background {
    flex-grow:1;
    min-height: 800px;
    display: flex;
    justify-content: center;
    position: relative;
    #bottom-empty{
      position: absolute;
      bottom:0;
    }
  }
  .custom-area {
    margin: 50px 0;
  }
  .nav-right {
    position: sticky;
    top: 0;
    align-self: flex-start;
    width: 400px;
    background: #fff;
    height: 777px;
    // height: calc(100vh - 160px);
  }
  .wrapper {
    z-index: 1;
  }
}
</style>
