<template>
  <div class="module-button-custom">

    <div class="module-style">
      <div class="target-module">
        <p>按钮外观</p>
        <div class="module-style-detail">
          <p>
            <span>按钮文案</span>
            <el-input
              type="text"
              placeholder="请输入内容"
              v-model="text"
              maxlength="10"
              show-word-limit
              style="width:150px;margin-right:10px"
            >
            </el-input>
            <el-radio-group v-model="radio1" size="small">
              <el-radio-button label="常规"></el-radio-button>
              <el-radio-button label="加粗"></el-radio-button>
            </el-radio-group>
          </p>
          <p>
            <span>字体色</span>
            <el-color-picker
              v-model="pickerFontColor"
              size="small"
            ></el-color-picker>
            <el-input
              v-model="fontColor"
              placeholder=""
              style="margin-left:10px;width:120px"
            >
              <template slot="prepend">#</template>
            </el-input>
          </p>
          <p>
            <span>边框色</span>
            <el-color-picker
              v-model="pickerBorderColor"
              size="small"
            ></el-color-picker>
            <el-input
              v-model="borderColor"
              placeholder=""
              style="margin-left:10px;width:120px"
            >
              <template slot="prepend">#</template>
            </el-input>
          </p>
          <p>
            <span>填充色</span>
            <el-color-picker
              v-model="pickerBgColor"
              size="small"
            ></el-color-picker>
            <el-input
              v-model="bgColor"
              placeholder=""
              style="margin-left:10px;width:120px"
            >
              <template slot="prepend">#</template>
            </el-input>
          </p>
        </div>
      </div>
    </div>
    <div class='one-button-attention'>
      <el-switch v-model="oneButtonAttention"></el-switch>
      <span style='margin-left:10px;font-weight:bold'>一键关注</span>
      <i class='el-icon-question tips' title='
唤起公众号简介的半屏面板，点击其中按钮直接关注公众号
由于部分用户可能未更新新版微信，请投放合约广告的广告主谨慎使用该功能"'></i>
    </div>
    <div class="margin-custom-style">
      <p>边距</p>
      <div class="setting-margin-distance">
        <div class="top-margin">
          <span>上边距</span>
          <el-slider v-model="marginTopValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginTopValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
        <div class="bottom-margin">
          <span>下边距</span>
          <el-slider v-model="marginBottomValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginBottomValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'imageModule',
  props: [''],
  data () {
    return {
      moduleConfig: [],
      text: '',
      radio1: '常规',
      pickerFontColor: '', // 颜色选择器的字体色
      pickerBorderColor: '', // 颜色选择器边框色
      pickerBgColor: '', // 颜色选择器填充色
      marginTopValue: 0, // 上边距默认值
      marginBottomValue: 0, // 下边距默认值
      copyCurModule: {}, //! 当前组件的副本 用于边距改变重新赋值,
      oneButtonAttention:false, //一键关注
    }
  },
  computed: {
    // 边距
    ...mapState(['marginInfo', 'curModuleSelected', 'channelName']),
    // 字体色
    fontColor: {
      get () {
        return this.pickerFontColor.slice(1)
      },
      set (value) {
        this.pickerFontColor = `#${value}`
      }
    },
    // 边框色
    borderColor: {
      get () {
        return this.pickerBorderColor.slice(1)
      },
      set (value) {
        this.pickerBorderColor = `#${value}`
      }
    },
    // 背景色
    bgColor: {
      get () {
        return this.pickerBgColor.slice(1)
      },
      set (value) {
        this.pickerBgColor = `#${value}`
      }
    }
  },
  watch: {
    // 监听curModuleSelected是为了在相同的组件切换的时候能单独渲染不同的样式
    curModuleSelected: {
      handler (newV) {
        if (newV && Object.keys(this.curModuleSelected).length > 0) {
          // 渲染对应组件的边距
          this.initData()
          this.initStyle()
        }
      },
      deep: true,
      immediate: true
    },
    marginTopValue: {
      handler (newV) {
        if (this.checkModuleProperty('marginTopValue')) {
          this.syncModuleStyle('marginTopValue', newV)
        }
      }
    },
    marginBottomValue: {
      handler (newV) {
        if (this.checkModuleProperty('marginBottomValue')) {
          this.syncModuleStyle('marginBottomValue', newV)
        }
      }
    },
    pickerFontColor: {
      handler (newV) {
        if (this.checkModuleProperty('buttonColor')) {
          this.syncModuleStyle('buttonColor', newV)
        }
      }
    },
    pickerBorderColor: {
      handler (newV) {
        if (this.checkModuleProperty('buttonBorderColor')) {
          this.syncModuleStyle('buttonBorderColor', newV)
        }
      }
    },
    pickerBgColor: {
      handler (newV) {
        if (this.checkModuleProperty('buttonBgColor')) {
          this.syncModuleStyle('buttonBgColor', newV)
        }
      }
    },
    text: {
      handler (newV) {
        if (this.checkModuleProperty('buttonText')) {
          this.syncModuleStyle('buttonText', newV)
        }
      }
    },
    radio1: {
      handler (newV) {
        const value = newV === '常规' ? 'normal' : 'bold'
        if (this.checkModuleProperty('fontWeightStyle')) {
          this.syncModuleStyle('fontWeightStyle', value)
        }
      }
    },
    // 一键关注
    oneButtonAttention:{
      handler(newV){
         if(this.checkModuleProperty('oneButtonAttention')){
           this.syncModuleStyle('oneButtonAttention',newV)
         }
      }
    }

  },
  mounted () {
    this.initStyle()
  },
  methods: {
    ...mapMutations(['setMarginInfo', 'setCurModuleSelected']),
    initData () {
      this.copyCurModule = JSON.parse(JSON.stringify(this.curModuleSelected))
    },
    initStyle () {
      if (
        this.curModuleSelected &&
        Object.keys(this.curModuleSelected).length !== 0
      ) {
        const {
          marginTopValue,
          marginBottomValue,
          buttonBgColor,
          buttonColor,
          buttonBorderColor,
          buttonText,
          oneButtonAttention,
        } = this.curModuleSelected
        this.marginTopValue = marginTopValue
        this.marginBottomValue = marginBottomValue
        this.pickerFontColor = buttonColor
        this.pickerBorderColor = buttonBorderColor
        this.pickerBgColor = buttonBgColor
        this.text = buttonText
        this.oneButtonAttention = oneButtonAttention
      }
    },

    checkModuleProperty (property) {
      const flag = `${property}` in this.curModuleSelected
      if (!flag) {
        this.$message({
          showClose: true,
          message: '暂不支持该修改',
          type: 'warning'
        })
      }
      return flag
    },
    syncModuleStyle (property, newV) {
      this.copyCurModule[`${property}`] = newV
      this.setCurModuleSelected(this.copyCurModule)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 26px;
  line-height: 18px;
}
/deep/.el-radio-group {
  height: 26px;
}
// /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
//   background: #07c160;
//   border-color: #07c160;
// }
.el-radio-button--small {
  /deep/ .el-radio-button__inner {
    padding: 0 15px;
    height: 26px;
    line-height: 26px;
  }
}
/deep/ .el-input-group__prepend {
  width: 10px;
  padding: 0 5px;
}
/deep/ .el-slider {
  width: 50%;
}
/deep/ .el-slider__button {
  height: 10px;
  width: 10px;
  // border-color:#07C160;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
/deep/ .el-slider__runway {
  height: 4px;
  .el-slider__bar {
    height: 4px;
    background-color: #07c160;
  }
}
/deep/ .el-input-number--small {
  width: 90px;
  margin-left: 10px;
}
.module-button-custom {
  font-size: 14px;
  .channel-info {
    text-align: left;
    .focus-channelName {
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      > p:nth-of-type(1) {
        font-weight: bold;
      }
      > p:nth-of-type(2) {
        margin-top: 5px;
        font-size: 12px;
        color: #787878;
      }
    }
    .popularize-channelName {
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      font-size: 12px;
      background: #fafafa;
      color: #787878;
      > p:nth-of-type(1) {
        font-weight: bold;
        font-size: #808080;
      }
      > p:nth-of-type(2) {
        margin-top: 5px;
      }
    }
    .bind-channelName {
      padding: 20px;
      border-bottom: 1px solid #f5f5f5;
      p {
        margin-bottom: 20px;
      }
      > p:nth-of-type(1) {
        font-weight: bold;
        font-size: 13px;
      }
      > p:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
  .module-style {
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #f5f5f5;
    .target-module {
      text-align: left;
      > p {
        margin-bottom: 10px;
        font-weight: bold;
      }
      .module-style-detail {
        > p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          margin-bottom: 20px;
          > span {
            width: 20%;
          }
        }
      }
    }
  }
  .one-button-attention{
    padding:20px;
    border-bottom:1px solid #f5f5f5;
    .tips{
      color:rgb(143, 143, 143);
      margin-left:5px;
    }
  }
  .margin-custom-style {
    > p {
      text-align: left;
      margin-top: 20px;
      margin-left: 20px;
    }
    .setting-margin-distance {
      padding: 20px;
      font-size: 12px;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          text-align: left;
          width: 20%;
        }
      }
    }
  }
}
</style>
