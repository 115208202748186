<template>
  <div class="aside-bar">
    <div class="base-module module">
      <h3>基础组件</h3>
      <el-collapse-transition>
        <div class="flex-start">
          <!-- <div
            class="base-module-item module-item"
            draggable="true"
            @dragstart="handleDragStart(index)"
            @drag="handleDrag"
            @dragend="handleDragEnd"
            @click="handleModuleClick(item)"
            v-for="(item, index) in moduleList"
            :key="item.id"
            :class="{
              'draggable-start': index === targetIndex && moduleDragStart
            }"
          > -->
          <div
            class="base-module-item module-item"
            draggable="true"
            @click="handleModuleClick(item)"
            v-for="(item, index) in moduleList"
            :key="item.id"
            :class="{
              'draggable-start': index === targetIndex && moduleDragStart
            }"
          >
            <i :class="`iconfont ${item.icon}`"></i>
            <span>{{ item.moduleName }}</span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
    <div class="button-conversion module">
      <h3>转化按钮</h3>
      <el-collapse-transition>
        <div class="flex-start">
          <div
            class="button-conversion-item module-item"
            draggable="true"
            v-for="(item, index) in buttonSettingList"
            :key="item.id"
            @click="handleModuleClick(item)"
            @dragstart="handleDragStart(index)"
            @drag="handleDrag"
            @dragend="handleDragEnd"
          >
            <i class="iconfont icon-gongzhonghao"></i>
            <span>{{ item.moduleName }}</span>
          </div>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
import { baseModule, buttonConversion } from '@/settings/setting'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'asideBar',
  data () {
    return {
      moduleList: [],
      targetIndex: 0, // 当前拖拽组件的索引标识
      buttonSettingList: []
    }
  },
  computed: {
    ...mapState(['moduleDragStart', 'tagNumber'])
  },
  mounted () {
    this.initData()
  },
  methods: {
    ...mapMutations([
      'setModuleDragStart',
      'setModuleClickStatus',
      'setModuleSelectedList',
      'setTagNumber',
      'setCurModuleSelected'
    ]),
    initData () {
      this.moduleList = JSON.parse(JSON.stringify(baseModule.config))
      this.buttonSettingList = JSON.parse(
        JSON.stringify(buttonConversion.config)
      )
    },
    // 拖拽开始
    handleDragStart (index) {
      this.targetIndex = index
      this.setModuleDragStart(true)
    },
    // 拖拽中
    handleDrag (e) {
    },
    // 拖拽结束
    handleDragEnd () {
      this.setModuleDragStart(false)
    },
    handleModuleClick (item) {
      const marginTopValue = 0
      const marginBottomValue = 0
      const targetObj = {
        ...item,
        marginTopValue:
          item.name === 'tipsButton'
            ? 14
            : item.name === 'tipsText'
              ? 11
              : marginTopValue, // ! 初始化上边距 直接赋值为0
        marginBottomValue:
          item.name === 'tipsButton'
            ? 14
            : item.name === 'tipsText'
              ? 11
              : marginBottomValue, // !初始化下边距
        fontSizeStyle: 14, // !默认字体大小(针对文本组件)
        fontWeightStyle: 'normal', // ! 默认字体weight设置(针对文本组件、按钮组件)
        fontColor: '#595959', // !默认字体颜色(针对文本组件、按钮组件)
        textAlignStyle: 'left', // !默认对齐方式(针对文本组件)
        promotedText: '', // ! 默认的文本域内容内容(针对文本组件)
        lineHeight: 1.5, // !行高(针对文本组件)
        buttonText: '关注公众号', // ! 默认按钮的文本内容(针对按钮组件)
        buttonBgColor: '#07C160', // !按钮组件的默认背景色(针对按钮组件)
        buttonColor: '#FFFFFF', // ! 按钮组件字体的默认颜色(针对按钮组件)
        buttonBorderColor: '#FFFFFF', // !按钮组件默认的边框颜色(针对按钮组件)
        oneButtonAttention:false, // !是否启用一键关注(针对按钮组件)
        imgUrl: '', // 图片组件的地址(针对图片组件)
        fileName: '', // 图片的文件名(针对图片组件)
        trueWidth: '', // 图片的真实宽度(针对图片组件)
        trueHeight: '', // 图片的真实高度(针对图片组件)
        imageFile: null, // 图片的文件信息(针对图片组件)
        tagNumber: this.tagNumber,
      }
      this.setModuleSelectedList(targetObj)
      this.setCurModuleSelected(targetObj)
      // 改变tagNumber的值
      this.setTagNumber()
      // !先变为true 再变为false
      this.setModuleClickStatus(true)
      this.setModuleClickStatus(false)
    }
  }
}
</script>
<style lang="scss" scoped>
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.iconfont {
  font-size: 20px;
}
.draggable-start {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 5px 10px 0 rgba(0, 0, 0, 0.15),
    0 0 0 1px #e0e0e0;
}
.aside-bar {
  padding: 16px 32px;
  border-right: 1px solid #f5f5f5;
  .module {
    text-align: left;
    h3 {
      padding-bottom: 10px;
      font-size: 16px;
    }
    .module-item {
      border: 1px solid #f5f5f5;
      width: 87px;
      height: 87px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #737373;
      &:hover {
        border: 1px solid rgba(214, 214, 214, 1);
        background: rgb(253, 253, 253);
      }
      > i {
        font-size: 24px;
        margin-bottom:5px;
      }
      > span {
        font-size: 13px;
      }
    }
  }
  .base-module {
    .base-module-item {
    }
  }
  .button-conversion {
    margin-top: 20px;
  }
}
</style>
