<template>
  <div class="edit-area">
    <div class="edit-area-container">
      <div class="bgcolor-showcase">
        <el-color-picker
          v-model="bgColor"
          size="mini"
          @active-change="handleActiveChange"

          :predefine="predefineColors"
        ></el-color-picker>
        <span style="font-size: 12px; margin-top: 5px">背景</span>
      </div>
      <div class="area" :style="AreaStyle" ref="area" v-loading="loading">

        <tips-draggable
          v-show="moduleDragStart"
          ref="tipsDrggable"
          :surplusHeight="surplusHeight"
          :tipsDraggableStyle="tipsDraggableStyle"
          :baseAreaHeight="baseAreaHeight"
        ></tips-draggable>
        <div class="custom-tips" ref="customTips">
          <draggable
            v-model="moduleSelectedList"
            draggable=".part"
            animation="300"
            @change="handleDraggableFormItemChange"
            @end="handleDragEnd"
          >
            <div
              class="part"
              v-for="(module, index) in moduleSelectedList"
              :key="index"
              :class="{ 'component-border': checkCompoonentBorder(module) }"
              @mousemove="handleModuleMove(module)"
            >
              <!-- @click="handleCurModuleClick(module, index)" -->
              <component
                v-show="setModuleClickStatus"
                class="component-module"
                :is="module.name"
                :key="module.tagNumber"
                :moduleTagNumber="module.tagNumber"
                :promotedText="promotedText"
                :ref="`componentModule`"
                :moduleInfo="module"
                @handleCurModuleClick="handleCurModuleClick(module, index)"
              >
                <!-- @handleCurModuleClick="handleCurModuleClick(module, index)" -->
              </component>
              <div class="custom-tips-operation">
                <div class="tips-drag">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="拖拽组件"
                    placement="right"
                  >
                    <i class="iconfont icon-tuozhuai"></i>
                  </el-tooltip>
                  <el-tooltip content="删除组件" placement="right">
                    <i
                      class="el-icon-delete-solid"
                      @click="handleModuleDelete"
                    ></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import tipsDraggable from './tips-draggable'
import tipsImage from './moduleTips/tips-image'
import tipsText from './moduleTips/tips-text'
import tipsButton from './moduleTips/tips-button'
import backUpDown from './../components/back-up-down'
import { mapState, mapMutations, mapActions } from 'vuex'
import store from '@/store'
const predefineColors = [
  '#FFFACD',
  '#F9ED8C',
  '#EEABBC',
  '#FFE4E1',
  '#F9EFD4',
  '#FFF4E3',
  '#FFEDD4',
  '#BBE0B5',
  '#FFFAD6',
  '#FFDEDE',
  '#F5DBBD',
  '#EDDECC',
  '#B9D6B4',
  '#FFF9CC',
  '#E0C9AC',
  '#E3CAAA',
  '#95CFA1',
  '#ADCCB4',
  '#D8E8D3'
]
export default {
  name: 'editArea',
  props: ['pageDetail'],
  data () {
    return {
      bgColor: '#FFFFFF',
      AreaStyle: {},
      surplusHeight: 0, // 剩余高度
      tipsDraggableStyle: {
        // tips的样式
        position: 'absolute'
      },
      baseAreaHeight: 667, // 编辑框初始高度
      curModuleIndex: 0,
      curModuleItem: {}, // 当编辑时 确认当前选中的组件
      // curModuleLength: 0, // * 当前组件的个数 用于在watch里面判断
      promotedText: '', // 推广文案(用于文本组件)
      moduleTagNumber: null,
      curHoverModule: null, // *鼠标移入module时的组件，用于删除时使用
      isDragEnter: false, // 是否拖入进入目标元素
      pageDetailItemLength: 0,
      loading: false,
      predefineColors
    }
  },
  computed: {
    ...mapState([
      'moduleDragStart',
      'moduleClickStatus',
      'moduleNumber',
      'curModuleSelected',
      'isPageUpdate',
      'editAreaStyle'
    ]),
    moduleSelectedList: {
      get () {
        return store.state.moduleSelectedList
      },
      set (value) {
        this.changeModuleSelectedOrder(value)
      }
    },
    curModuleLength: {
      get () {
        return store.state.moduleSelectedList.length
      },
      set () {}
    }
  },
  watch: {
    editAreaStyle: {
      handler (newV) {
        const curHeight = parseFloat(
          window.getComputedStyle(this.$refs.area).getPropertyValue('height')
        )
        const targetHeight = parseFloat(newV.height)
        if (curHeight <= 768) {
          this.$set(this.AreaStyle, 'height', `${targetHeight}px`)
        }
      }
    },
    bgColor: {
      handler (newV) {
        window.localStorage.setItem('bgColor', JSON.stringify(newV))
        this.initStyle()
        this.renderCyclingStyle(this.moduleSelectedList)
      }
    },
    // * 用于编辑页面的回显操作
    pageDetail: {
      handler (newV) {
        if (newV) {
          // ! 需要在这里给moduleNumber重新赋值
          this.setTargetModuleNumber(newV.items.length)
          this.echoPage(newV)
        }
      },
      immediate: true
    },
    moduleSelectedList: {
      handler (newV) {
        if (newV.length !== this.moduleNumber) {
          this.curModuleIndex = newV.length - 1
          if (newV.length > this.moduleNumber) {
            this.moduleTagNumber = newV[newV.length - 1].tagNumber
            this.setCurModuleSelected(newV[newV.length - 1])
            this.setModuleNumber()
            this.pageDetailItemLength++
          }
          // !当用户删除组件的时候 进入此判断
          if (newV.length < this.moduleNumber) {
            this.setModuleNumber('subtraction')
            this.pageDetailItemLength--
            this.$nextTick(() => {
              this.renderCyclingStyle(newV)
            })
          }
        }
        // this.curModuleLength = newV.length
      },
      deep: true
    },
    curModuleSelected: {
      handler (newV) {
        // 防止删除报错
        if (newV) {
          this.renderModuleStyle(newV)
        }
      },
      deep: true
    },
    moduleClickStatus: {},
    moduleDragStart: {
      handler (newV) {
        if (newV) {
          this.$nextTick(() => {
            const areaHeight = parseFloat(
              window
                .getComputedStyle(this.$refs.area)
                .getPropertyValue('height')
            )
            const customTipsHeight = parseFloat(
              window
                .getComputedStyle(this.$refs.customTips)
                .getPropertyValue('height')
            )
            if (customTipsHeight < 667) {
              this.surplusHeight = this.baseAreaHeight - customTipsHeight
            }
            if (customTipsHeight >= 667) {
              this.$refs.area.style.height = `${areaHeight + 40}px`
              this.baseAreaHeight = areaHeight + 40
              this.surplusHeight = 40
            }
          })
        } else {
          this.$refs.area.style.height = 'auto'
          this.baseAreaHeight = parseFloat(
            window.getComputedStyle(this.$refs.area).getPropertyValue('height')
          )
        }
      }
    }
  },
  mounted () {
    this.loading = true
    // setTimeout(() => {
    if (this.curModuleSelected) {
      this.moduleTagNumber = this.curModuleSelected.tagNumber
    }
    if (this.moduleSelectedList.length > 0) {
      // ! 用于回显初始化moduleNumber
      if (this.isPageUpdate) {
        this.setTargetModuleNumber(this.moduleSelectedList.length)
      }
      // ! 刷新之后重新渲染之前的数据
      this.renderCyclingStyle(this.moduleSelectedList)
    }

    if (window.localStorage.getItem('bgColor')) {
      this.bgColor = JSON.parse(window.localStorage.getItem('bgColor'))
    }
    // this.initStyle();

    // 计算高度
    this.computedHeight()
    this.loading = false
    // },1000);
  },
  methods: {
    ...mapMutations([
      'setModuleDragStart',
      'setModuleClickStatus',
      'changeModuleSelectedList',
      'setCurModuleSelected',
      'changeModuleSelectedOrder',
      'setModuleNumber',
      'setEditAreaStyle',
      'setCanvasName',
      'setTagNumber',
      'setShare',
      'setTargetModuleNumber'
    ]),
    ...mapActions(['delModuleSelectedItem']),
    renderModuleStyle (val) {
      this.$nextTick(() => {
        // !获取目前的组件,根据不同的组件来对应渲染不同的样式
        this.renderBaseModuleStyle(val)
        // !改变状态之后,将值保存在vuex当中
        this.changeModuleSelectedList(val)
      })
    },
    initStyle () {
      this.$set(this.AreaStyle, 'backgroundColor', this.bgColor)
      this.setEditAreaStyle({
        backgroundColor: this.bgColor
      })
    },
    computedHeight () {
      this.$nextTick(() => {
        const height = window
          .getComputedStyle(this.$refs.area)
          .getPropertyValue('height')
        this.surplusHeight = height
      })
    },
    checkCompoonentBorder (module) {
      return this.moduleTagNumber === module.tagNumber
    },
    handleCurModuleClick (module, index) {
      this.curModuleIndex = index
      this.curModuleItem = this.moduleSelectedList.find(
        item => item.tagNumber === module.tagNumber
      )
      // !边框通过module的tagNumber来判断 防止 点击组件时 边框会异常跳转到最后一个module的bug
      this.moduleTagNumber = this.curModuleItem.tagNumber
      this.setCurModuleSelected(this.curModuleItem)
    },
    // 监听当拖动元素进入容器中时触发
    // 删除单个组件
    handleModuleDelete () {
      if (this.curHoverModule) {
        this.setCurModuleSelected(null)
        this.delModuleSelectedItem(
          JSON.parse(JSON.stringify(this.curHoverModule))
        )
        // // 将moduleNumber的数量减少1
        // this.setModuleNumber("subtraction");
      }
    },
    // !渲染高亮当前组件
    renderBaseModuleStyle (val, index = null) {
      this.curModuleIndex = this.moduleSelectedList.findIndex(
        module => module.tagNumber === val.tagNumber
      )

      const { name } = val
      const targetIndex = index || this.curModuleIndex
      const domList = [...document.querySelectorAll('.component-module')]
      if (domList.length > 0) {
        // if(this.pageDetailItemLength === domList.length+1 ){

        switch (name) {
          case 'tipsText':
            domList[targetIndex].style = `
            font-size:${val.fontSizeStyle}px;
            color:${val.fontColor};
            background-color:${this.bgColor};
            text-align:${val.textAlignStyle};
            font-weight:${val.fontWeightStyle};
            line-height:${val.lineHeight ? val.lineHeight : 1.5};
            margin-top:${val.marginTopValue}px;
            margin-bottom:${val.marginBottomValue}px;
           `
            break
          case 'tipsImage':
            domList[targetIndex].style = `
            margin-top:${val.marginTopValue}px;
            margin-bottom:${val.marginBottomValue}px;
            `
            break
          case 'tipsButton':
            break
        }
      }
    },
    renderCyclingStyle (moduleList) {
      for (let i = 0; i < moduleList.length; i++) {
        this.renderBaseModuleStyle(moduleList[i], i)
      }
    },
    handleDraggableFormItemChange (data, index) {},
    handleModuleMove (module) {
      if (!this.curHoverModule) {
        this.curHoverModule = module
        return
      }
      if (this.curHoverModule.tagNumber !== module.tagNumber) {
        this.curHoverModule = module
      }
    },
    handleDragEnd (e) {
      // 拖拽借宿之后循环渲染组件的样式
      this.$nextTick(() => {
        this.renderCyclingStyle(this.moduleSelectedList)
      })
    },
    // 回显编辑页面数据
    echoPage (val) {
      const arr = []
      const { canvasName, shareTitle, shareDesc, backgroundColor } = val
      val.items.forEach(item => {
        const {
          // content,
          // name,
          paddingBottom,
          paddingTop,
          // widgetType,
          // btnBgColorTheme,
          // btnBorderColorTheme,
          textAlignment,
          fontColor,
          fontSize,
          // btnHeight,
          // btnTitle,
          showType
        } = item
        const typeInfo = this.getModuleType(item)
        const targetItem = {
          moduleName: typeInfo.moduleName,
          name: typeInfo.name,
          styleModuleName: typeInfo.styleModuleName,
          id: typeInfo.id,
          marginTopValue: paddingTop / 2,
          marginBottomValue: paddingBottom / 2,
          fontSizeStyle: fontSize / 2,
          fontWeightStyle: showType === 0 ? 'normal' : 'bold',
          fontColor: fontColor || '#595959',
          textAlignStyle:
            textAlignment === 0
              ? 'left'
              : textAlignment === 1
                ? 'center'
                : 'right',
          promotedText: typeInfo.promotedText,
          lineHeight: typeInfo.lineHeight,
          buttonText: typeInfo.buttonText,
          buttonBgColor: typeInfo.buttonBgColor || '#07C160',
          buttonColor: typeInfo.buttonColor || '#FFFFFF',
          buttonBorderColor: typeInfo.buttonBorderColor || '#FFFFFF',
          imgUrl: typeInfo.imgUrl || '',
          fileName: typeInfo.fileName,
          trueWidth: typeInfo.trueWidth,
          trueHeight: typeInfo.trueHeight,
          imageFile: typeInfo.imageFile,
          tagNumber: typeInfo.tagNumber,
          // 回显数据新增对按钮组件新增一键关注
          oneButtonAttention:typeInfo.oneButtonAttention,
          
        }
        arr.push(targetItem)
      })
      const maxTagNumber = Math.max(...arr.map(item => item.tagNumber))
      // !回显tagNumber
      this.setTagNumber(maxTagNumber)
      this.setCanvasName(canvasName)
      this.setShare({
        shareTitle,
        shareDescription: shareDesc
      })
      this.changeModuleSelectedOrder(arr)
      this.$nextTick(() => {
        this.renderCyclingStyle(this.moduleSelectedList)
        this.bgColor = backgroundColor
      })
    },
    getModuleType (detail) {
      switch (detail.widgetType) {
        case 'image':
          return {
            moduleName: '图片',
            name: 'tipsImage',
            styleModuleName: 'imageModule',
            icon: 'icon-tupian',
            id: detail.sort,
            promotedText: '',
            imgUrl: detail.content,
            tagNumber: detail.sort,
            lineHeight: 1.5,
            buttonText: '',
            imageFile: null,
            fileName: '',
            trueWidth: '',
            trueHeight: '',
            buttonBgColor: '',
            buttonColor: '',
            buttonBorderColor: ''
          }
        case 'text':
          return {
            moduleName: '文本',
            name: 'tipsText',
            styleModuleName: 'textModule',
            icon: 'icon-wenzi',
            id: detail.sort,
            imgUrl: '',
            buttonText: '',
            promotedText: detail.content,
            tagNumber: detail.sort,
            imageFile: null,
            lineHeight: 1.5,
            fileName: '',
            trueWidth: '',
            trueHeight: '',
            buttonBgColor: '',
            buttonColor: '',
            buttonBorderColor: ''
          }
        case 'button':
          return {
            moduleName: '关注公众号',
            name: 'tipsButton',
            styleModuleName: 'buttonModule',
            id: detail.sort,
            lineHeight: 1.5,
            imageFile: null,
            promotedText: '',
            buttonText: detail.content,
            tagNumber: detail.sort,
            buttonBgColor: detail.btnBgColorTheme,
            imgUrl: '',
            fileName: '',
            trueWidth: '',
            trueHeight: '',
            buttonColor: detail.fontColor,
            buttonBorderColor: detail.btnBorderColorTheme,
            oneButtonAttention:Number(detail.subType) === 17 ? true : false
          }
      }
    },
    handleActiveChange (val) {
      this.bgColor = val
    },
    getBgColor (color) {
      return {
        backgroundColor: `${color}`
      }
    },
    setBgColor (color) {
      this.bgColor = color
    }
  },
  components: {
    tipsDraggable,
    tipsImage,
    tipsText,
    tipsButton,
    draggable,
    backUpDown
  }
}
</script>

<style lang="scss" scoped>
.page-component_scroll {
  height: 667px;
  width: 102%;
  /deep/ .el-scrollbar_wrap {
    overflow: auto;
  }
}
.default-bgColor {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 50px;
  p {
    height: 28px;
    width: 28px;
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-size: 0;
    position: relative;
    cursor: pointer;
    margin: 5px 0;
  }
}
.edit-area {
  .edit-area-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .bgcolor-showcase {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
    .area {
      width: 375px;
      min-height: 667px;
      background: #fff;
      position: relative;
      // !解决div中间有间隔的问题
      font-size: 0;
      // box-sizing: content-box;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.03),
        0 6px 15px 0 rgba(0, 0, 0, 0.04), 0 0 0 1px hsla(0, 0%, 87%, 0.6);
    }
    .custom-tips {
      // box-sizing: content-box;
      .part {
        position: relative;
        display: inline-block;
        font-size: 0;
        .custom-tips-operation {
          display: none;

          .tips-drag {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 0 1px hsla(0, 0%, 87%, 0.2),
              0 3px 6px 0 rgba(0, 0, 0, 0.04);
            i {
              color: #666;
              font-size: 16px;
              margin: 10px;
              cursor: grab;
              cursor: -webkit-grab;
              &:nth-of-type(1) {
                font-size: 20px;
                cursor: grab;
                cursor: -webkit-grab;
              }
              &:nth-of-type(2) {
                cursor: pointer;
                cursor: -webkit-pointer;
              }
            }
          }
        }
        &:hover {
          .custom-tips-operation {
            display: block;
            position: absolute;
            left: 100%;
            width: 30px;
            background: #fff;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            top: 0;
          }
        }
      }
    }
  }
}
.component-border {
  // box-sizing: content-box;
  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 0 2px #07c160 !important;
  }
}
.part {
  width: 100%;
  // box-sizing: content-box;
  font-size: 0;
  position: relative;
  &:hover::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    box-shadow: 0 0 0 1px #07c160;
  }
}
.component-module {
  height: auto;
}
</style>
