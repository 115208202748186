<template>
  <div class="text-custom">
    <div class="text-custom-detail">
      <div class="text-custom-name">
        <p>文本</p>
      </div>
      <div class="text-source-setting">
        <p>推广文案</p>
        <el-input
          type="textarea"
          v-model="promotedText"
          placeholder="请输入"
          :class="{ 'textarea-warning': isShowTipsLimit }"
          :autosize="{ minRows:8,maxRows: 8 }"
          @focus="handleFocus"
          @input="handleTextAreaInput"
        ></el-input>
        <div class="limit-tips" v-show="isShowTipsLimit">必须为1-10000字</div>
        <p>
          <a href="https://ad.weixin.qq.com/landing-page-guide.html#/379" target="_blank" >查看文案审核规范</a>
        </p>
      </div>
    </div>
    <div class="text-style">
      <p>字符与段落</p>
      <div class="text-font-detail">
        <div class="text-font-style">
          <span>字符样式</span>
          <el-select v-model="fontSize" size="mini">
            <el-option
              v-for="(item, index) in fontSizeList"
              :label="item.value"
              :value="item.value"
              :key="index"
            ></el-option>
          </el-select>
          <el-color-picker
            v-model="fontColor"
            size="mini"
            style="margin-left:10px"
          ></el-color-picker>
          <el-radio-group v-model="radio1" style="margin-left:65px" size="mini">
            <el-radio-button label="常规"></el-radio-button>
            <el-radio-button label="加粗"></el-radio-button>
          </el-radio-group>
        </div>
        <div class="text-font-align">
          <span>对齐方式</span>
          <el-radio-group v-model="radio2" style="margin-left:10px" size="mini">
            <el-radio-button label="left">
              <!-- <i class="iconfont icon-zuoduiqi" style="font-size:12px"></i> -->
              左
            </el-radio-button>
            <el-radio-button label="center">
              <!-- <i
                class="iconfont icon-chuizhijuzhong"
                style="font-size:12px"
              ></i> -->
              中
            </el-radio-button>
            <el-radio-button label="right">
              <!-- <i class="iconfont icon-youduiqi" style="font-size:12px"></i> -->
              右
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div class="margin-custom-style">
      <p>边距</p>
      <div class="setting-margin-distance">
        <div class="top-margin">
          <span>上边距</span>
          <el-slider v-model="marginTopValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginTopValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
        <div class="bottom-margin">
          <span>下边距</span>
          <el-slider v-model="marginBottomValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginBottomValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'textModule',
  data () {
    return {
      moduleConfig: [],
      text: '关注公众号',
      fontColor: '#595959', // 颜色选择器的字体色
      marginTopValue: 0, // 上边距默认值
      marginBottomValue: 0, // 下边距默认值
      copyCurModule: {}, //! 当前组件的副本 用于边距改变重新赋值
      promotedText: '', // 推广文案
      isShowTipsLimit: false, // 是否显示文字提示,
      fontSize: 14,
      radio2: 'left',
      radio1: '常规',
      fontSizeList: [
        {
          id: 1,
          value: 14
        },
        {
          id: 2,
          value: 15
        },
        {
          id: 3,
          value: 16
        },
        {
          id: 4,
          value: 18
        },
        {
          id: 5,
          value: 20
        },
        {
          id: 6,
          value: 24
        },
        {
          id: 7,
          value: 36
        }
      ]
    }
  },
  computed: {
    // 边距
    ...mapState(['marginInfo', 'curModuleSelected'])
    // 字体色
  },
  watch: {
    curModuleSelected: {
      handler (newV) {
        if (newV && Object.keys(this.curModuleSelected).length > 0) {
          // 渲染对应组件的边距
          this.initData()
          this.initStyle()
        }
      },
      deep: true,
      immediate: true
    },
    marginTopValue: {
      handler (newV) {
        if (this.checkModuleProperty('marginTopValue')) {
          this.syncModuleStyle('marginTopValue', newV)
        }
      }
    },
    marginBottomValue: {
      handler (newV) {
        if (this.checkModuleProperty('marginBottomValue')) {
          this.syncModuleStyle('marginBottomValue', newV)
        }
      }
    },
    // 文本内容变化
    promotedText: {
      handler (newV) {
        if (this.checkModuleProperty('promotedText')) {
          // !通过vuex同步目前组件的样式
          this.syncModuleStyle('promotedText', newV)
        }
      }
    },
    // 字体大小变化
    fontSize: {
      handler (newV) {
        if (this.checkModuleProperty('fontSizeStyle')) {
          this.syncModuleStyle('fontSizeStyle', newV)
        }
      }
    },
    // 字体weight的变化
    radio1: {
      handler (newV) {
        const value = newV === '常规' ? 'normal' : 'bold'
        if (this.checkModuleProperty('fontWeightStyle')) {
          this.syncModuleStyle('fontWeightStyle', value)
        }
      }
    },
    // 对齐方式变化
    radio2: {
      handler (newV) {
        if (this.checkModuleProperty('textAlignStyle')) {
          this.syncModuleStyle('textAlignStyle', newV)
        }
      }
    },
    // 字体颜色的变化
    fontColor: {
      handler (newV) {
        if (this.checkModuleProperty('fontColor')) {
          this.syncModuleStyle('fontColor', newV)
        }
      }
    }
  },
  mounted () {
    this.initStyle()
  },
  methods: {
    ...mapMutations([
      'setMarginInfo',
      'setCurModuleSelected',
      'setPromotedText'
    ]),
    initData () {
      this.copyCurModule = JSON.parse(JSON.stringify(this.curModuleSelected))
    },
    initStyle () {
      if (Object.keys(this.curModuleSelected).length !== 0) {
        const {
          marginTopValue,
          marginBottomValue,
          fontSizeStyle,
          fontWeightStyle,
          fontColor,
          textAlignStyle,
          promotedText
        } = this.curModuleSelected
        this.marginTopValue = marginTopValue
        this.marginBottomValue = marginBottomValue
        this.promotedText = promotedText
        this.fontSize = fontSizeStyle
        this.fontColor = fontColor
        this.radio1 = fontWeightStyle === 'normal' ? '常规' : '加粗'
        this.radio2 = textAlignStyle
      }
    },
    checkModuleProperty (property) {
      const flag = `${property}` in this.curModuleSelected
      if (!flag) {
        this.$message({
          showClose: true,
          message: '暂不支持该修改',
          type: 'warning'
        })
      }
      return flag
    },
    syncModuleStyle (property, newV) {
      this.copyCurModule[`${property}`] = newV
      this.setCurModuleSelected(this.copyCurModule)
    },
    // 获取焦点时触发
    handleFocus () {
      if (this.promotedText) {
        this.isShowTipsLimit = false
        return
      }
      this.isShowTipsLimit = true
    },
    handleTextAreaInput (val) {
      this.isShowTipsLimit = !val
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 26px;
  line-height: 18px;
}
/deep/.el-radio-group {
  height: 26px;
}

/deep/ .el-slider {
  width: 50%;
}
/deep/ .el-slider__button {
  height: 10px;
  width: 10px;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
/deep/ .el-slider__runway {
  height: 4px;
  .el-slider__bar {
    height: 4px;
    background-color: #07c160;
  }
}
/deep/ .el-input-number--small {
  width: 90px;
  margin-left: 10px;
}

.textarea-warning {
  /deep/ .el-textarea__inner {
    border: 1px solid #d9514c;
    box-shadow: 0 0 0 1px rgba(241, 76, 76, 0.07),
      0 5px 1px 0 rgba(243, 105, 105, 0.1),
      0 10px 0px 0 rgba(250, 166, 166, 0.07);
  }
}

a {
  color: #459ae9;
  text-decoration: none;
}
.text-custom {
  font-size: 14px;
  text-align: left;
  .text-custom-detail {
    .text-custom-name {
      padding: 20px 28px;
      font-weight: bold;
      border-bottom: 1px solid #eaeaea;
    }
    .text-source-setting {
      padding: 28px;
      border-bottom: 1px solid #eaeaea;
      > p:nth-of-type(1) {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 13px;
      }
      > p:nth-of-type(2) {
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .limit-tips {
      margin-top: 10px;
      color: #d9514c;
      font-size: 12px;
    }
  }
  .text-style {
    padding: 28px;
    border-bottom: 1px solid #eaeaea;
    > p {
      font-size: 13px;
      font-weight: bold;
    }
    .text-font-detail {
      font-size: 12px;
      .text-font-style {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          margin-right: 10px;
        }
        /deep/ .el-input {
          width: 60px;
        }
      }
      .text-font-align {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /deep/ .el-radio-button__inner {
          width: 92px;
        }
      }
    }
  }
}

.margin-custom-style {
  padding: 28px;
  border-bottom: 1px solid #eaeaea;
  > p {
    text-align: left;
    font-weight: bold;
  }
  .setting-margin-distance {
    font-size: 12px;
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        text-align: left;
        width: 20%;
      }
    }
  }
}
</style>
