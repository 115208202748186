<template>
  <div class="image-custom">
    <div class="image-custom-detail">
      <div class="image-custom-name">
        <p>图片</p>
      </div>
      <div class="image-source-setting">
        <p>素材设置</p>
        <div>
          <span>图片素材</span>
          <input
            type="file"
            ref="file"
            style="display:none;"
            @change="handleUpload"
          />
          <div class="image-info">
            <div
              class="small-size"
              v-if="!curModuleSelected.imgUrl"
              @click="uploadImage"
            >
              <i class="iconfont icon-21"></i>
            </div>
            <img
              :src="curModuleSelected.imgUrl"
              alt="缩约图"
              class="small-size"
              v-else
              @click="uploadImage"
            />
            <span class="image-tips">
              暂不支持含有二维码的图片<br />
              图片格式：大小不超过300KB，不支持Gif<br />
              图片尺寸：宽度750像素，高度不超过1536像素<br />
              <span style='color:red'>裁剪图片时请保持图片的宽度等于750像素</span>
              <br />
              <br />
              <a href="https://ad.weixin.qq.com/landing-page-guide.html#/376" target='__blank'>查看详细图片规范</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-custom-style">
      <p>边距</p>
      <div class="setting-margin-distance">
        <div class="top-margin">
          <span>上边距</span>
          <el-slider v-model="marginTopValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginTopValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
        <div class="bottom-margin">
          <span>下边距</span>
          <el-slider v-model="marginBottomValue" :max='50' input-size="mini"> </el-slider>
          <el-input-number
            v-model="marginBottomValue"
            controls-position="right"
            size="small"
          ></el-input-number>
        </div>
      </div>
    </div>
    <cropperModel
      :cropperVisible="cropperVisible"
      :imageUrl="url"
      :trueWidth="trueWidth"
      :trueHeight="trueHeight"
      :fileName="fileName"
      :needCropWidth='375'
      :needCropHeight='375'
      :myCropperWidth='750/1.5'
      :myCropperHeight='750/1.5'
      :fixedBox='false'
      @cropSuccess="handleCropSuccess"
      @close="cropperVisible = false"
    ></cropperModel>
  </div>
</template>

<script>
// 最大高度
import { mapMutations, mapState } from 'vuex'
import cropperModel from './../cropper-model'
import { uploadFile } from '@/api/common'
const LIMIWIDTH = 750 // 最大宽度
const LIMITHEIGHT = 1536
export default {
  name: 'imageModule',
  data () {
    return {
      channelName: '丘山看书',
      cropperVisible: false,
      moduleConfig: [],
      url: '',
      radio1: '常规',
      pickerFontColor: '#FFFFFF', // 颜色选择器的字体色
      pickerBorderColor: '#FFFFFF', // 颜色选择器边框色
      pickerBgColor: '#07C160', // 颜色选择器填充色
      marginTopValue: 0, // 上边距默认值
      marginBottomValue: 0, // 下边距默认值
      copyCurModule: {}, //! 当前组件的副本 用于边距改变重新赋值
      trueWidth: 0,
      trueHeight: 0,
      fileName: ''
    }
  },
  computed: {
    // 边距
    ...mapState(['marginInfo', 'curModuleSelected']),
    // 字体色
    fontColor: {
      get () {
        return this.pickerFontColor.slice(1)
      },
      set (value) {
        this.pickerFontColor = `#${value}`
      }
    },
    // 边框色
    borderColor: {
      get () {
        return this.pickerBorderColor.slice(1)
      },
      set (value) {
        this.pickerBorderColor = `#${value}`
      }
    },
    // 背景色
    bgColor: {
      get () {
        return this.pickerBgColor.slice(1)
      },
      set (value) {
        this.pickerBgColor = `#${value}`
      }
    }
  },
  watch: {
    marginTopValue: {
      handler (newV) {
        this.copyCurModule.marginTopValue = newV
        this.setCurModuleSelected(this.copyCurModule)
      }
    },
    marginBottomValue: {
      handler (newV) {
        this.copyCurModule.marginBottomValue = newV
        this.setCurModuleSelected(this.copyCurModule)
      }
    },
    curModuleSelected: {
      handler (newV) {
        if (newV && Object.keys(this.curModuleSelected).length > 0) {
          // 渲染对应组件的边距
          this.initData()
          this.initStyle()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.initStyle()
  },
  methods: {
    ...mapMutations([
      'setMarginInfo',
      'setCurModuleSelected',
      'setIsUploadImage'
    ]),
    initData () {
      this.copyCurModule = JSON.parse(JSON.stringify(this.curModuleSelected))
    },
    initStyle () {
      if (Object.keys(this.curModuleSelected).length !== 0) {
        this.marginTopValue = this.curModuleSelected.marginTopValue
        this.marginBottomValue = this.curModuleSelected.marginBottomValue
      }
    },
    handleUpload (e) {
      const imageFile = e.target ? e.target.files[0] : e.srcElement.files[0]
      const fr = new FileReader()
      if (imageFile.size > 2000 * 1024) {
        this.$message({
          showClose: true,
          message: '您上传的图片太大，请重新上传!',
          type: 'error'
        })
        return
      }
      this.$refs.file.value = ''
      fr.readAsDataURL(imageFile)
      fr.onload = e => {
        const url = e.currentTarget.result
        const image = new Image()
        image.src = url
        image.onload = async () => {
          const { width, height } = image
          if (width < LIMIWIDTH) {
            this.$message({
              showClose: true,
              message: '您选择的图片宽度太小,请重新上传!',
              type: 'error'
            })
            return
          }
          // 如果图片的宽高不符合要求
          if (height > LIMITHEIGHT) {
            this.$message({
              showClose: true,
              message: '您选择的图片高度太高,请重新上传!',
              type: 'error'
            })
            return
          }
          // 如果宽度刚好等于750 则不需要裁剪直接显示
          if (width === LIMIWIDTH) {
            const res = await uploadFile({ file: imageFile })
            this.copyCurModule.imgUrl = res
            this.setCurModuleSelected(this.copyCurModule)
            return
          }
          this.url = e.currentTarget.result
          this.cropperVisible = true
        }
      }
    },
    handleCropSuccess (file) {
      const targetFile = file
      uploadFile({ file: targetFile }).then(res => {
        this.copyCurModule.imgUrl = res
        this.setCurModuleSelected(this.copyCurModule)
        this.cropperVisible = false
      })
      // const fr = new FileReader()
      // fr.readAsDataURL(targetFile)
      // fr.onload = e => {
      //   this.copyCurModule.imgUrl = e.currentTarget.result
      //   this.setCurModuleSelected(this.copyCurModule)
      //   this.cropperVisible = false
      // }
    },
    uploadImage () {
      if ('file' in this.$refs) {
        this.$refs.file.click()
      }
    }
  },
  components: {
    cropperModel
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 26px;
  line-height: 18px;
}
/deep/.el-radio-group {
  height: 26px;
}
/deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #07c160;
  border-color: #07c160;
}
.el-radio-button--small {
  /deep/ .el-radio-button__inner {
    padding: 0 15px;
    height: 26px;
    line-height: 26px;
  }
}
/deep/ .el-input-group__prepend {
  width: 10px;
  padding: 0 5px;
}
/deep/ .el-slider {
  width: 50%;
}
/deep/ .el-slider__button {
  height: 10px;
  width: 10px;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
/deep/ .el-slider__runway {
  height: 4px;
  .el-slider__bar {
    height: 4px;
    background-color: #07c160;
  }
}
/deep/ .el-input-number--small {
  width: 90px;
  margin-left: 10px;
}
a {
  color: #459ae9;
  text-decoration: none;
}
.image-custom {
  font-size: 14px;
  text-align: left;
  .image-custom-detail {
    .image-custom-name {
      padding: 20px 28px;
      font-weight: bold;
      border-bottom: 1px solid #eaeaea;
    }
    .image-source-setting {
      padding: 28px;
      border-bottom: 1px solid #eaeaea;
      > p {
        font-weight: bold;
        margin-bottom: 16px;
      }
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          align-self: flex-start;
          font-size: 12px;
          width: 70px;
        }
        .image-info {
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .small-size {
            width: 72px;
            height: 72px;
            border-radius: 6px;
            object-fit: cover;
            border: 2px dashed #e3e3e3;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .image-tips {
            margin-top: 10px;
            font-size: 12px;
            color: #a3a3a3;
            line-height: 1.3;
          }
        }
      }
    }
  }

  .margin-custom-style {
    padding: 28px;
    border-bottom: 1px solid #eaeaea;
    > p {
      text-align: left;
      font-weight: bold;
    }
    .setting-margin-distance {
      font-size: 12px;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        > span {
          text-align: left;
          width: 20%;
        }
      }
    }
  }
}
</style>
