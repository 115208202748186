<template>
  <div class="tips-draggable" :style="tipsDraggableStyle">
    <span> 请拖拽至此处 </span>
  </div>
</template>
<script>
export default {
  name: 'tipsDraggable',
  props: ['surplusHeight', 'tipsDraggableStyle', 'baseAreaHeight'],
  data () {
    return {}
  },
  watch: {
    tipsDraggableStyle: {
      handler () {
        this.initStyle()
      },
      deep: true,
      immediate: true
    },
    // ! 当高度低于<=原有高度667px时 监听surplusHeight进行动态改变样式
    surplusHeight: {
      handler (newV) {
        this.initStyle()
      }
    },
    // ! 原有高度>=667px时 监听编辑区area的高度进行动态对top进行赋值
    baseAreaHeight: {
      handler (newV) {
        this.initStyle()
      }
    }
  },
  mounted () {
    this.initStyle()
  },
  methods: {
    initStyle () {
      this.$nextTick(() => {
        this.$set(this.tipsDraggableStyle, 'height', `${parseFloat(this.surplusHeight)}px`)
        // ! tips是绝对定位 所以需要动态对top进行赋值
        this.$set(this.tipsDraggableStyle, 'top', `${this.baseAreaHeight - this.surplusHeight}px`)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tips-draggable {
  position: absolute;
  width: 375px;
  border: 2px dashed rgb(7, 193, 96);
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px;
    font-weight: bold;
    color: rgb(7, 193, 96);
  }
}
</style>
